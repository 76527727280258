import React from 'react';
import { Link, graphql } from 'gatsby';

const AboutPage = ({ data }) => (
  <React.Fragment>
    <h2>{data.site.siteMetadata.title}</h2>
    <div>{data.site.siteMetadata.description}</div>
    <Link to="/">Back to home</Link>
  </React.Fragment>
)

export const Head = () => <title>About Me</title>
export default AboutPage; 

export const query = graphql `
query {
    site {
      siteMetadata {
        siteUrl
        author {
          name
          summary
        }
        title
        description
      }
    }
  }
`